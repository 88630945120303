import React, { useContext, useRef, useState } from "react";
import CustomButton from "../button/Button";
import Select from "react-select";
import CreateBookIcon from "../../assets/icons/create-book.svg";
import { useParams } from "react-router-dom";
import BrainstormCard from "./BrainstormCard";
import { Pagination, Spin } from "antd";
import { HeaderDataContext } from "../../context/HeaderContext";
import AddBrainstormDrawer from "./AddBrainStormDrawer";
import EditBrainstormDrawer from "./EditBrainstormDrawer";
import PackageSubscriptionHook from "../PackageSubscriptionHook";
import { SubscriptionModal } from "../subscriptionodal";
import { useBrainstorms } from "../../hooks/brainstrom/useBrainstrom";
import { CommentsDrawer } from "../comments";
import { axiosInstance } from "../../services/apiServices/config";
import { useDispatch, useSelector } from "react-redux";
import { setBrainstormValues } from "../../redux/Slice/BrainStormSlice";
import FullPageSpinner from "../loader/FullPageSpinner";

const Brainstorms = () => {
	const token = localStorage.getItem("token");
	const header = useContext(HeaderDataContext);
	header.setHeading("Brainstorms");
	const { id } = useParams();
	const ref = useRef(null);

	const {
		books,
		brainstorms,
		bstorm,
		totalRecords,
		currentPage,
		selectedBrainstorm,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		loading,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSubscriptionModal,
		setClickedButton,
		getBrainstormsByBook,
		handlePageClick,
		editBrainstorm,
		deleteBrainstorm,
		checkSubscription,
		openSubscriptionModal,
	} = useBrainstorms(id, token);

	const BrainstormToDisplay = selectedBook.value === "" ? bstorm : brainstorms;

	const dispatch = useDispatch();
	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({
		isOpen: false,
		id: null,
	});
	const [commentsState, setCommentsState] = useState({});
	const [newCommentState, setNewCommentState] = useState("");

	const showCommentDrawer = (comments, id) => {
		setCommentsState((prevState) => ({
			...prevState,
			[id]: comments.map((i) => ({ author: "you", comment: i.comment })),
		}));
		setIsCommentDrawerOpen({ id, isOpen: true });
	};
	console.log(bstorm);

	const handleCommentDrawerClose = () => {
		setIsCommentDrawerOpen({ isOpen: false, id: null });
	};

	const handleAddComment = async () => {
		if (newCommentState.trim()) {
			const postComments = await axiosInstance.post("/comments", {
				comment_type: "brainstorm",
				comment: newCommentState,
				brainstorm_id: isCommentDrawerOpen.id,
			});
			if (postComments.data) {
				const item = bstorm.find((i) => i.id == isCommentDrawerOpen.id);
				if (item) {
					const updatedComments = [
						...item.comments,
						{
							id: item.comments.length + 1,
							author: "You",
							comment: newCommentState,
						},
					];
					const updatedItem = { ...item, comments: updatedComments };
					const updatedBstorm = bstorm.map((i) =>
						i.id === isCommentDrawerOpen.id ? updatedItem : i
					);
					dispatch(setBrainstormValues(updatedBstorm));
				}
				setCommentsState((prevState) => ({
					...prevState,
					[isCommentDrawerOpen.id]: [
						...prevState[isCommentDrawerOpen.id],
						{
							id: prevState[isCommentDrawerOpen.id].length + 1,
							author: "You",
							comment: newCommentState,
						},
					],
				}));
				setNewCommentState("");
			}
		}
	};

	return (
		<div className="main-container">
			<PackageSubscriptionHook
				feature={["brainstorming-feature"]}
				ref={ref}
				title="Brainstorm"
				checkPackageSubscription={checkSubscription}
			/>
			<div className="list-actions brain-st-list">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle="Add Brainstorm"
						onClick={() =>
							controlledButtons.createBrainstorm
								? setShowAddNewDrawer(true)
								: (setClickedButton([{ feature: "brainstorming-feature" }]),
								  openSubscriptionModal())
						}
						icon={<img src={CreateBookIcon} alt="New Brainstorm" />}
					/>
				</div>

				{books.length > 0 && (
					<div className="filter">
						<label>Select book:</label>
						<Select
							options={[{ value: "", label: "-- Any Book --" }, ...books]}
							onChange={getBrainstormsByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
				)}
			</div>

			<div className="card-container mt-2">
				<div className="p-3">
					{loading && BrainstormToDisplay.length === 0 ? (
						<FullPageSpinner />
					) : BrainstormToDisplay.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No brainstorm to display, click on Add Brainstorm to add new.
						</h4>
					) : (
						BrainstormToDisplay.map((brainstorm) => {
							return (
								<BrainstormCard
									key={brainstorm.id}
									brainstorm={brainstorm}
									showAddNewDrawer={showAddNewDrawer}
									style={{
										backgroundColor: brainstorm.color_code,
										color: brainstorm.foreground_color,
										cursor: "pointer",
									}}
									showCommentDrawer={() =>
										showCommentDrawer(brainstorm.comments, brainstorm.id)
									}
									comments={brainstorm.comments}
									commentsCount={brainstorm.comments.length ?? 0}
									editBrainstorm={editBrainstorm}
									deleteBrainstorm={deleteBrainstorm}
									setShowAction={setShowEditDrawer}
								/>
							);
						})
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords * 10}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>
			<AddBrainstormDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getBrainstormsByBook={getBrainstormsByBook}
			/>
			<EditBrainstormDrawer
				brainstorm={selectedBrainstorm}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getBrainstormsByBook={getBrainstormsByBook}
			/>
			<SubscriptionModal
				open={subscriptionModal}
				ok={openSubscriptionModal}
				features={clickedButton}
				cancel={() => setSubscriptionModal(false)}
			/>
			<CommentsDrawer
				mod="auther"
				hasEditor={true}
				isCommentDrawerOpen={isCommentDrawerOpen.isOpen}
				handleCommentDrawerClose={handleCommentDrawerClose}
				comments={commentsState[isCommentDrawerOpen.id] || []}
				newComment={newCommentState}
				setNewComment={setNewCommentState}
				handleAddComment={handleAddComment}
			/>
		</div>
	);
};

export default Brainstorms;
