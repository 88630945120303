import React, { useState } from "react";
import TimelineEvent from "../cards/timelineEvent";
import { Button, Row, Col, Popover, Modal } from "antd";
import BookNameOutlineCard from "../cards/outline-cards/book-name-outline/BookNameOutlineCard";
import "../../pages/screen/index.scss";
import Menu from "../../assets/icons/menu.svg";
import { useDispatch } from "react-redux";
import {
	deleteEvent,
	getEventTypeList,
	updateEvent,
} from "../../redux/Slice/TimelineSlice";
import UpdateEventModal from "../UpdateEventModal";
import AddEventModal from "./timeline-events/AddEventModal";
import { AUTHENTICATED_ROUTES } from "../../constant/routeConstant";
import { useTimelineDetails } from "../../hooks/timelines/useTimelineDetials";
import FullPageSpinner from "../loader/FullPageSpinner";

const Content = ({ e_id, path_id, close }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const dispatch = useDispatch();

	const deleteevent = (eid) => {
		dispatch(deleteEvent(eid));
		setTimeout(() => {
			dispatch(getEventTypeList({ id: path_id }));
		}, 1000);
	};

	const showUpdateModal = () => setIsModalOpen(true);
	const handleUpdateOk = () => setIsModalOpen(false);
	const handleUpdateCancel = () => setIsModalOpen(false);

	const EditEvent = (data) => {
		try {
			const payload = { id: e_id, data: data };
			dispatch(updateEvent(payload));
			setTimeout(() => {
				dispatch(getEventTypeList({ id: path_id }));
			}, 1000);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div>
			<div
				className="edit"
				onClick={(e) => {
					e.preventDefault();
					showUpdateModal();
					close();
				}}
			>
				<button>
					Edit
					<span className="ml-2">
						<i className="fa fa-check"></i>
					</span>
				</button>
			</div>

			<div className="delete-btn">
				<button
					onClick={() => {
						deleteevent(e_id);
						close();
					}}
				>
					Delete
					<span className="ml-2">
						<i className="fa fa-times"></i>
					</span>
				</button>
			</div>
			<UpdateEventModal
				open={isModalOpen}
				ok={handleUpdateOk}
				cancel={handleUpdateCancel}
				onSubmit={EditEvent}
				event_id={e_id}
				id={path_id}
			/>
		</div>
	);
};

const Timeline = (props) => {
	const { mod = "auther" } = props;
	const {
		hasEditor,
		id,
		geustData,
		name,
		desc,
		events,
		isModalOpen,
		openIndex,
		popupVisible,
		selectedEvent,
		setSelectedEvent,
		showModal,
		handleOk,
		handleCancel,
		close,
		open,
		handleOpenChange,
		AddEvent,
		isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
		loading,
	} = useTimelineDetails({ mod });

	console.log(hasEditor, mod);
	return (
		<div className="main-container">
			<BookNameOutlineCard
				link={AUTHENTICATED_ROUTES.TIME_LINE}
				name={!loading ? name : "Loading Name..."}
				description={!loading ? desc : "Loading Description..."}
				mod={mod}
			/>
			{loading ? (
				<FullPageSpinner />
			) : (
				<>
					<div className="timelineDiv">
						<Row gutter={24}>
							{events[0]?.length > 0 &&
								events[0]?.map((event) => (
									<Col sm={12} xs={12} lg={6} md={12} xl={6} key={event.id}>
										<div className="mb-5 d-flex justify-between">
											<div
												className="chapter-name d-flex justify-between"
												onClick={() => setSelectedEvent(event.event_type)}
											>
												<span
													style={{
														width: "18px",
														height: "18px",
														background: event.color_code,
														borderRadius: "110px",
													}}
													className="mr-2"
												></span>
												<p className="mr-3">{event.event_type}</p>
												<div>
													<span>
														{hasEditor && mod !== "guest" ? (
															<Popover
																id={event.id}
																placement="rightBottom"
																content={
																	<Content
																		e_id={event.id}
																		path_id={id}
																		close={close}
																	/>
																}
																title="Actions"
																trigger="click"
																style={{ cursor: "pointer" }}
																openIndex={openIndex}
																popupVisible={popupVisible}
																onOpenChange={handleOpenChange}
																open={event.id === openIndex && popupVisible}
															>
																<img
																	src={Menu}
																	className="ml-4"
																	alt="menu-icon"
																	onClick={() => open(event.id)}
																/>
															</Popover>
														) : (
															mod !== "guest" && (
																<img
																	src={Menu}
																	className="ml-4"
																	alt="menu-icon"
																	onClick={showEditorModal}
																/>
															)
														)}
													</span>
												</div>
											</div>
										</div>
									</Col>
								))}
							{mod !== "guest" && (
								<Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
									<Button
										type="primary"
										size="small"
										className="addButton mb-2"
										style={{ cursor: "pointer" }}
										onClick={hasEditor ? showModal : showEditorModal}
									>
										New Event Type
									</Button>
									<AddEventModal
										handleOk={handleOk}
										handleCancel={handleCancel}
										isModalOpen={isModalOpen}
										onSubmit={AddEvent}
									/>
								</Col>
							)}
						</Row>

						<div id="columns-background" className="table-grid">
							<div className="characterDiv">
								<TimelineEvent
									selectedEvent={selectedEvent}
									hasEditor={hasEditor && mod !== "guest"}
									mod={mod}
									geustData={geustData}
									showEditorModal={showEditorModal}
								/>
							</div>
						</div>
					</div>
				</>
			)}
			<Modal
				title="Access Denied"
				visible={isEditorModalVisible}
				onOk={handleEditorModalOk}
				onCancel={handleEditorModalCancel}
				centered
				footer={null}
			>
				<p>You do not have permission to perform this action.</p>
			</Modal>
		</div>
	);
};

export default Timeline;
