import "./calendar.css";
import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteCalendarEvent,
	postCalenderList,
	updateCalendarEvent,
} from "../../redux/Slice/CalendarSlice";
import moment from "moment";
import CalendarEventDrawer from "./CalendarEventDrawer";
import { apiServices } from "../../services/apiServices/Api";
import axios from "axios";

const CalendarTab = ({ filteredCalendarData }) => {
	const [activeTab, setActiveTab] = useState("dayGridMonth");
	const dispatch = useDispatch();
	const { colors, tags } = useSelector((state) => state.colors);
	const { calendar_list } = useSelector((state) => state.calendar);
	const [eventDrawer, setEventDrawer] = useState(false);
	const [updatedEvent, setUpdatedEvent] = useState();
	const [loading, setLoading] = useState(false);
	const [actionModal, setActionModal] = useState(false);
	const [eventID, setEventId] = useState();
	const [todos, setTodos] = useState([]);
	const [updatedTodo, setUpdatedTodo] = useState();
	const calendarRef = useRef(null);
	const dateFormat = "YYYY-MM-DD";
	const [eventDate, setEventDate] = useState("");
	const [form] = Form.useForm();

	const Time = (time) => {
		var start = new Date(),
			s = time,
			parts = s.match(/(\d+):(\d+) (\w+)/),
			hours = /am/i.test(parts[3])
				? parseInt(parts[1], 10)
				: parseInt(parts[1], 10) + 24,
			minutes = parseInt(parts[2], 10);
		start.setHours(hours, minutes, 0);
		return start;
	};

	useEffect(() => {
		const updatedEventData = calendar_list?.find((c) => c?.id === eventID);
		setUpdatedEvent(updatedEventData);
	}, [calendar_list, eventID, postCalenderList, updateCalendarEvent]);

	useEffect(() => {
		if (calendarRef.current) {
			const calendarApi = calendarRef.current.getApi();
			calendarApi.changeView(activeTab);
		}
	}, [activeTab]);

	const showActionModal = () => {
		setActionModal(true);
	};
	const handleActionOk = () => {
		setActionModal(false);
	};
	const handleActionCancel = () => {
		setActionModal(false);
	};

	const showDrawer = async (type) => {
		type === "Todo" && (await getTodos());
		setEventDrawer(true);
		setActionModal(false);
	};
	const closeEventDrawer = () => {
		setEventDrawer(false);
	};

	const getTodos = async (cardId) => {
		try {
			const response = await apiServices.get(
				`${process.env.REACT_APP_API_URL}/card-tasks-list/${cardId}`,
				{}
			);
			const data = response.data;
			setTodos(
				data.map((d) => ({
					item: d.todo_item,
					date: d.todo_date,
					time: d.todo_time,
					id: d.id,
				}))
			);
			return true;
		} catch (err) {
			console.log("Error while getting todo", err);
		}
	};

	const postTodo = async (newSortArray, cardId) => {
		const token = localStorage.getItem("token");
		const { data } = await axios.post(
			`${process.env.REACT_APP_API_URL}/card-tasks`,
			{
				todos: newSortArray,
				card_id: cardId,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setLoading(false);
		if (data) {
			return true;
		} else {
			return false;
		}
	};

	const Todos = async (cardId, payload) => {
		try {
			if (await getTodos(cardId)) {
				let indexToRemove = todos.findIndex(
					(obj) => obj.id === payload.data.id
				);
				if (indexToRemove !== -1) {
					todos.splice(indexToRemove, 1, {
						item: payload.data.title,
						date: payload.data.event_date,
						time: payload.data.start_time,
						id: payload.data.id,
					});
					setTodos([...todos]);
				}
				payload.data.end_time = payload.data.start_time;
				return true;
			} else {
				return false;
			}
		} catch (err) {
			console.log("Error while handling todo", err);
			return false;
		}
	};

	const handleSubmit = async (e) => {
		setLoading(true);
		let data = { ...e };
		const payload = { id: eventID, data: data };
		if (updatedEvent?.type === "Todo") {
			if (await Todos(data.card_id, payload)) {
				await postTodo(todos, data.card_id);
			}
		} else {
			dispatch(updateCalendarEvent(payload)).then(() => {
				dispatch(
					postCalenderList({
						date_from: moment().startOf("month").format("DD-MM-YYYY"),
						date_to: moment().endOf("month").format("DD-MM-YYYY"),
					})
				);
				setLoading(false);
			});
		}
		form.resetFields();
		closeEventDrawer();
	};

	const deleteEvent = async () => {
		dispatch(deleteCalendarEvent(eventID)).then(() => {
			dispatch(
				postCalenderList({
					date_from: moment().startOf("month").format("DD-MM-YYYY"),
					date_to: moment().endOf("month").format("DD-MM-YYYY"),
				})
			);
			setActionModal(false);
		});
	};

	const convertDateTime = (eventDate, endDate, startTime, endTime) => {
		const formatDateTime = (date, time) => {
			// Parse the date and time strings into a moment object
			const dateTime = moment(`${date} ${time}`, "MMMM DD, YYYY hh:mm a");
			// Check if the date is valid
			if (!dateTime.isValid()) {
				console.log(date, time);
				throw new RangeError("Invalid time value");
			}
			// Format as 'YYYY-MM-DDTHH:MM:SS'
			return dateTime.format("YYYY-MM-DDTHH:mm:ss");
		};

		const start = formatDateTime(eventDate, startTime);
		const end = formatDateTime(endDate, endTime);

		return { start, end };
	};

	const events = calendar_list
		?.map((c) => {
			try {
				const { start, end } = convertDateTime(
					c.event_date,
					c.event_date,
					c.start_time,
					c.end_time
				);

				return {
					title: c?.title,
					start: start,
					tag_id: c?.tag_id,
					end: end,
					color: colors.find(
						(color) =>
							color.id === tags.find((tag) => tag.id === c?.tag_id)?.color?.id
					)?.color_code,
					description: c?.description,
					id: c.id,
					start_time: moment(Time(c?.start_time).getTime(), "hh:mm").format(
						"HH:mm"
					),
					end_time: moment(Time(c?.end_time).getTime(), "hh:mm").format(
						"HH:mm"
					),
					col: c?.color_id,
					event_date: moment(c?.event_date).format("DD-MM-YYYY"),
					location: c?.location,
				};
			} catch (error) {
				console.error("Error converting date time for event:", c, error);
				return null;
			}
		})
		.filter((event) => event !== null);

	console.log(events, "events");

	console.log(filteredCalendarData, "filteredCalendarData");
	return (
		<div>
			<div className="tab-container tab-new-container">
				<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
					<li className="nav-item" role="presentation">
						<button
							className="nav-link active"
							id="pills-home-tab"
							data-bs-toggle="pill"
							data-bs-target="#pills-home"
							type="button"
							role="tab"
							aria-controls="pills-home"
							aria-selected="true"
							onClick={() => setActiveTab("dayGridMonth")}
						>
							Month
						</button>
					</li>
					<li className="nav-item" role="presentation">
						<button
							className="nav-link"
							id="pills-profile-tab"
							data-bs-toggle="pill"
							data-bs-target="#pills-profile"
							type="button"
							role="tab"
							aria-controls="pills-profile"
							aria-selected="false"
							onClick={() => setActiveTab("timeGridWeek")}
						>
							Week
						</button>
					</li>
					<li className="nav-item" role="presentation">
						<button
							className="nav-link"
							id="pills-contact-tab"
							data-bs-toggle="pill"
							data-bs-target="#pills-contact"
							type="button"
							role="tab"
							aria-controls="pills-contact"
							aria-selected="false"
							onClick={() => setActiveTab("timeGridDay")}
						>
							Day
						</button>
					</li>
					<li className="nav-item" role="presentation">
						<button
							className="nav-link"
							id="pills-contact-tab"
							data-bs-toggle="pill"
							data-bs-target="#pills-contact"
							type="button"
							role="tab"
							aria-controls="pills-contact"
							aria-selected="false"
							onClick={() => setActiveTab("timeGridDay")}
						>
							List
						</button>
					</li>
				</ul>
			</div>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane fade show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<FullCalendar
						ref={calendarRef}
						selectable={true}
						editable={false}
						height={763}
						viewClassNames={activeTab}
						themeSystem="bootstrap5"
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						headerToolbar={{
							left: "prev,next today",
							center: "title",
							right: "",
						}}
						events={
							filteredCalendarData[0].filtered
								? filteredCalendarData[0].data
								: events
						}
						displayEventEnd="true"
						navLinks={true}
						eventLimit={true}
						eventClick={function (calEvent) {
							showActionModal();
							setEventId(parseInt(calEvent.event._def.publicId));
							setEventDate(calEvent.event._def.extendedProps.event_date);
						}}
					/>
					<Modal
						title="Edit/Delete"
						open={actionModal}
						onOk={handleActionOk}
						onCancel={handleActionCancel}
						className="chapterModal"
						mask={false}
						width={350}
						style={{
							marginLeft: "45%",
							marginRight: "auto",
							position: "relative",
							marginTop: "12%",
							padding: "1.2rem",
							height: "20rem",
						}}
					>
						<div className="buttons-container mt-3 d-flex space-between p-3">
							<Button
								type="primary"
								onClick={() => showDrawer(updatedEvent?.type)}
								className="mr-3"
							>
								Edit
							</Button>
							<Button onClick={deleteEvent} danger>
								Delete
							</Button>
						</div>
					</Modal>
					<CalendarEventDrawer
						open={eventDrawer}
						close={closeEventDrawer}
						isUpdate={true}
						id={eventID}
						onSubmit={handleSubmit}
						form={form}
						loading={loading}
						updatedEvent={updatedEvent}
						startTime={moment(updatedEvent?.start_time, "h:mm A").format(
							"HH:mm"
						)}
						endTime={moment(updatedEvent?.end_time, "hh:mm A").format("HH:mm")}
						eventDate={eventDate}
					/>
				</div>
			</div>
		</div>
	);
};

export default CalendarTab;
