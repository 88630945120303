import {
	Drawer,
	Input,
	Button,
	Select,
	Switch,
	Form,
	Checkbox,
	Divider,
	Modal,
} from "antd";
import React from "react";
import "./outline-side-modal/outlinemodal.css";
import newBook from "../assets/images/newbook.png";
import TextArea from "antd/lib/input/TextArea";
import UserGallery from "./gallery/UserGallery";
import { CollaborativeShareField } from "./collaborativeShareField";
import { useCreateNewBookDrawer } from "../hooks/books/useCreateNewBook&Series";

export const CreateNewBookDrawer = ({
	open,
	close,
	onSubmit,
	status: drawerStatus,
}) => {
	const {
		form,
		forms,
		selectedFile,
		setSelectedFile,
		showGallery,
		setShowGallery,
		customErrors,
		handleChange,
		handleClose,
		handleSubmit,
		handleCollaboratorRole,
		closeCollaboratorRole,
		currentUser,
		selectedUsers,
		setSelectedUsers,
		colors,
		seriesData,
		book_data,
		bookList,
		drawerStatusProp,
		users,
	} = useCreateNewBookDrawer({
		open,
		close,
		onSubmit,
		drawerStatusProp: drawerStatus,
	});

	return (
		<Drawer
			title={drawerStatus === 1 ? "Create new Series" : "Create new Book"}
			placement="right"
			onClose={handleClose}
			open={open}
			className="p-1 drawer data-drawer drawer-flow"
			zIndex={9999}
			autoFocus={false}
			mask={false}
			style={{ position: "fixed", width: "auto" }}
		>
			<Form
				ref={form}
				form={forms}
				labelCol={{
					span: 24,
				}}
				wrapperCol={{
					span: 24,
				}}
				layout="vertical"
				initialValues={{
					remember: false,
				}}
				onFinish={handleSubmit}
				autoComplete="off"
				className="gx-signin-form gx-form-row0"
				style={{
					position: "relative",
				}}
			>
				<Form.Item label="Artwork">
					{showGallery && (
						<UserGallery
							setSelectedFile={setSelectedFile}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={(e) => {
										e.preventDefault();
										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
					{customErrors.image_url && (
						<div className="error text-danger">{customErrors.image_url}</div>
					)}
				</Form.Item>
				<Form.Item
					label="Title"
					name={drawerStatus === 1 ? "series_name" : "book_name"}
					rules={[
						{
							required: true,
							message: "Name is required",
						},
					]}
				>
					<Input
						placeholder={drawerStatus === 1 ? "Series Name" : "Book Name"}
					/>
				</Form.Item>
				<Form.Item
					label="Description"
					name={drawerStatus === 1 ? "series_description" : "book_description"}
					rules={[
						{
							required: true,
							message: "Description is required",
						},
					]}
				>
					<TextArea name="book_description" placeholder="Add Description" />
				</Form.Item>
				{drawerStatus === 2 && (
					<Form.Item
						label="Series"
						name={"series_id"}
						rules={[
							{
								required: false,
								message: "Please select Series",
							},
						]}
					>
						<Select options={seriesData[0]} placeholder="Series Name" />
					</Form.Item>
				)}
				<Form.Item
					label="Color"
					name={"color_id"}
					rules={[
						{
							required: true,
							message: "Color is required",
						},
					]}
				>
					<Select options={colors} placeholder="Select Color" />
				</Form.Item>
				{drawerStatus === 1 && (
					<Form.Item label="Other books in series name" name="books">
						{book_data[0]?.length > 0 &&
							book_data[0]?.map((b, index) => {
								return (
									<li key={index} style={{ listStyle: "none" }}>
										<Checkbox
											name="books"
											value={[...bookList.books, b.id]}
											onChange={handleChange}
											className="gray"
										>
											<p style={{ textDecoration: "underline" }}>
												<span className="mr-2">
													<img
														src={b?.image_url}
														width="20"
														height="20"
														alt=""
													/>
												</span>
												{b?.book_name}
											</p>
										</Checkbox>
									</li>
								);
							})}
					</Form.Item>
				)}
				<Form.Item
					label={
						drawerStatus === 1
							? "Is the Series finished"
							: "Is the Book finished"
					}
					name={"is_finished"}
					valuePropName="checked"
				>
					<Switch />
				</Form.Item>
				<Divider />
				{drawerStatus !== 1 && (
					<Form.Item label="Share Book" name="share_book">
						<CollaborativeShareField
							isRequired={false}
							handleCollaboratorRole={handleCollaboratorRole}
							closeCollaboratorRole={closeCollaboratorRole}
							Form={forms}
							users={users}
							selectedUsers={selectedUsers}
							setSelectedUsers={setSelectedUsers}
						/>
					</Form.Item>
				)}
				<div className="buttons-container d-flex mt-2">
					<Button type="primary" htmlType="submit" className="mr-2">
						Add
					</Button>
					<Button onClick={handleClose} type="ghost">
						Cancel
					</Button>
				</div>
			</Form>
			<Modal
				centered
				title="Select Collaborator Role"
				open={currentUser.visible && currentUser.email !== ""}
				onOk={closeCollaboratorRole}
				onCancel={closeCollaboratorRole}
			>
				<Form.Item
					label="Role"
					name="role"
					rules={[{ required: true, message: "Please select a role" }]}
				>
					<Select
						placeholder="Select a role"
						defaultValue={
							selectedUsers.find((item) => item.email === currentUser.email)
								?.role
						}
						onSelect={(value) => {
							setSelectedUsers(
								selectedUsers.map((item) =>
									item.email === currentUser.email
										? { ...item, role: value }
										: item
								)
							);
						}}
					>
						<Select.Option value="viewer">Viewer</Select.Option>
						<Select.Option value="editor">Editor</Select.Option>
					</Select>
				</Form.Item>
			</Modal>
		</Drawer>
	);
};
