import React from "react";
import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Select,
} from "antd";
import Productivity from "../../assets/images/Productivity.svg";
import CalendarTab from "../../components/Calendar/CalendarTab.js";
import CalendarEventDrawer from "../../components/Calendar/CalendarEventDrawer";
import "../../components/Calendar/calendar.css";
import PackageSubscriptionHook from "../../components/PackageSubscriptionHook";
import { MdEdit } from "react-icons/md";
import useCalendar from "../../hooks/calendar/useCalendar";
import { SubscriptionModal } from "../../components/subscriptionodal.js";

const Calendar = () => {
	const {
		header,
		colors,
		addEvent,
		loading,
		calendarSubscription,
		addTagsModal,
		setAddTagsModal,
		tag,
		tags,
		setTag,
		editTagsModal,
		filteredTagEditData,
		ref,
		form,
		showDrawer,
		setNewTag,
		selectedColor,
		setSelectedColor,
		closeEventDrawer,
		checkSubscription,
		addData,
		handleTagsModal,
		handleAddTags,
		handleTagsAdd,
		filterTagForEdit,
		handleEditTags,
		setEditTagsModal,
		handleTagsFilter,
		filteredCalendarData,
		setFilteredTagEditData,
		handleAddTag,
		handleEditTag,
		handleDeleteTag,
		clickedButton,
		setClickedButton,
		subscriptionModal,
		setSubscriptionModal,
		tagsModalError,
		setTagsModalError,
		tagsAddError,
		setTagsAddError,
	} = useCalendar();

	console.log(loading);
	return (
		<div className="main-container">
			<PackageSubscriptionHook
				feature="goals-calendar-feature"
				packageName="calendar-feature"
				ref={ref}
				title="Calendar"
				checkPackageSubscription={checkSubscription}
			/>
			<Row className="disp-block">
				<Col sm={6} lg={4} xs={12} md={8} className="width-full">
					<Card className="new-calend-rp">
						<div className="calendar-col-1">
							<div>
								<Button
									type="primary"
									style={{ width: "100%", padding: "20px 0px" }}
									onClick={showDrawer}
								>
									Add New Events
								</Button>
								<Button
									type="primary"
									style={{
										marginTop: "10px",
										width: "100%",
										padding: "20px 0px",
									}}
									onClick={() => handleTagsModal(true)}
								>
									Add New Tags
								</Button>
								<div className="mt-4">
									{tags?.length > 0 && (
										<MdEdit
											className="size-20 opacity-0"
											onClick={() => {
												setEditTagsModal(true);
												setTagsModalError("");
											}}
											style={{ cursor: "pointer", float: "right" }}
										/>
									)}
									<ul
										className="decoration-none flex-col"
										style={{ paddingLeft: "0" }}
									>
										{tags?.length > 0 &&
											tags.map((data) => (
												<li
													className="flex"
													style={{ alignItems: "center" }}
													key={data.value}
												>
													<Checkbox
														className={data.color}
														onChange={handleTagsFilter}
														style={{ display: "contents" }}
														value={data.id}
													/>
													<span
														className="ml-2"
														style={{
															textDecoration: "none",
															fontSize: "14px",
															marginLeft: "8px",
															textTransform: "capitalize",
														}}
													>
														{data.label}
													</span>
												</li>
											))}
										{tags?.length === 0 && !loading && (
											<div
												className="no-tags-message"
												style={{
													padding: "10px",
													border: "1px dashed #ccc",
													borderRadius: "5px",
													backgroundColor: "#f9f9f9",
												}}
											>
												<p style={{ fontSize: "14px", color: "#555" }}>
													No tags available. Add some to organize your events!
												</p>
											</div>
										)}
									</ul>
								</div>
							</div>
							<div className="mt-5" id="productivity-image">
								<img src={Productivity} alt="productivity" />
							</div>
						</div>
					</Card>
				</Col>
				<Col sm={18} lg={20} xs={12} md={16} className="width-full">
					<Card id="calendar-card" style={{ padding: 0 }}>
						<CalendarTab filteredCalendarData={filteredCalendarData} />
					</Card>
				</Col>
			</Row>
			<CalendarEventDrawer
				tags={tags}
				open={addEvent}
				close={closeEventDrawer}
				onSubmit={addData}
				isUpdate={false}
				loading={loading}
				form={form}
			/>
			{addTagsModal && (
				<Modal
					open={true}
					title="Add New Tag"
					centered
					onCancel={() => {
						setAddTagsModal(false);
						setTagsAddError("");
						setSelectedColor(null);
						setTag("");
					}}
					footer={[
						<Button
							key="cancel"
							type="primary"
							danger
							onClick={() => handleTagsModal(false)}
						>
							Cancel
						</Button>,
						<Button
							key="add"
							type="primary"
							disabled={loading}
							onClick={
								selectedColor !== null && tag.length > 0
									? handleTagsAdd
									: () =>
											setTagsAddError(
												"Please select a color and enter a tag name"
											)
							}
						>
							Add
						</Button>,
					]}
				>
					<Input onChange={(e) => handleAddTags(e)} />
					<Form.Item
						label="Colors"
						name="color_id"
						labelCol={{ span: 24 }}
						className="mb-3 mt-2"
						rules={[{ required: true, message: "Please Select a Color!" }]}
					>
						<Select
							options={colors}
							placeholder="Select Color"
							onChange={(value) => {
								setSelectedColor(value);
								tag.length > 0 && setTagsAddError("");
							}}
						/>
						{tagsAddError && <p style={{ color: "red" }}>{tagsAddError}</p>}
					</Form.Item>
				</Modal>
			)}
			{editTagsModal && (
				<Modal
					open={true}
					title="Edit Tags"
					cancelText="Delete"
					okText="Edit"
					cancelButtonProps={{ danger: true }}
					onCancel={() => {
						setEditTagsModal(false);
						setFilteredTagEditData([]);
					}}
					footer={[
						<Button
							disabled={loading}
							key="delete"
							type="primary"
							danger
							onClick={
								filteredTagEditData?.length > 0
									? handleDeleteTag
									: () => setTagsModalError("Please select a tag to delete")
							}
						>
							Delete
						</Button>,
						<Button
							key="edit"
							type="primary"
							disabled={loading}
							onClick={
								filteredTagEditData?.length > 0
									? handleEditTag
									: () => setTagsModalError("Please select a tag to edit")
							}
						>
							Edit
						</Button>,
					]}
					centered
				>
					<Input
						value={filteredTagEditData && filteredTagEditData[0]?.label}
						onChange={(e) => handleEditTags(e.target.value)}
					/>
					<Form.Item
						label="Tags"
						name="tags"
						labelCol={{ span: 24 }}
						className="mb-3 mt-2"
						rules={[{ required: true, message: "Please Select a Color!" }]}
					>
						<Select
							options={tags}
							onSelect={filterTagForEdit}
							placeholder="Select Tag"
							onChange={(value) => setSelectedColor(value)}
						/>
					</Form.Item>
					{tagsModalError && <p style={{ color: "red" }}>{tagsModalError}</p>}
				</Modal>
			)}
			<SubscriptionModal
				open={subscriptionModal}
				ok={subscriptionModal}
				features={clickedButton}
				cancel={() => setSubscriptionModal(false)}
			/>
		</div>
	);
};

export default Calendar;
