import { Form, Input, Select, Button, Drawer, DatePicker, Avatar, Upload, Modal } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import newBook from "../../assets/images/newbook.png";
import { getChapterCards, postChapterCards } from "../../redux/Slice/ChapterCardsSlice";
import { getChapters } from "../../redux/Slice/ChapterSlice";
import "./eventbutton.css";
import { getUsers } from "../../redux/Slice/UserSlice";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import UserGallery from "../gallery/UserGallery";
import { getTimelineList } from "../../redux/Slice/TimelineSlice";
import { getPlotlineList } from "../../redux/Slice/PlotlineSlice";
import { CollaborativeShareField } from "../collaborativeShareField";
import { axiosInstance } from "../../services/apiServices/config";
import { getBrainstorms } from "../../helpers/GetList";

const EventItemCard = ({ event_id, hasEditor, showEditorModal }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const { book_data } = useSelector((state) => state.books);
	const { bstorm } = useSelector((state) => state.brainstorm);
	const { timelineData } = useSelector((state) => state.timeline);
	const { plotplanner, plotlines } = useSelector((state) => state.plotline);
	const { id } = useParams();
	const [form] = Form.useForm();
	const { colors } = useSelector((state) => state.colors);
	const { users } = useSelector((state) => state.user);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [showGallery, setShowGallery] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [customErrors, setCustomErrors] = useState({});
	const dispatch = useDispatch();
	const [currentUser, setCurrentUser] = useState({ email: '', visible: false });

	const showDrawer = useCallback(() => setIsDrawerOpen(true), []);
	const handleOk = useCallback(() => setIsDrawerOpen(false), []);
	const handleCancel = useCallback(() => {
		setIsDrawerOpen(false);
		form.resetFields();
		setTasks([]);
	}, [form]);

	const SubmitCard = useCallback(async (values) => {
		try {
			const data = {
				card_title: values.card_title,
				card_description: values.description,
				color_id: values.color_id,
				image_id: selectedFile?.id,
				tasks,
				chapter_id: parseInt(event_id),
			};
			form.resetFields();
			handleCancel();
			const response = await dispatch(postChapterCards({ data }));
			const cardId = response.payload.data.id;

			const attachmentPromises = values.attachments?.fileList.map(item => {
				const attachedForm = new FormData();
				attachedForm.append('file_path', item.originFileObj);
				attachedForm.append('chapter_card_id', cardId);
				return axiosInstance.post('/attachments', attachedForm);
			}) || [];

			await Promise.all(attachmentPromises);
			dispatch(getUsers());

			setTimeout(async () => {
				setSelectedFile(null);
				await dispatch(getChapterCards({ id }));
				const collaboratorData = {
					email: tasks.map(i => users[0]?.data?.find(user => user.id === i.collabration_id)?.email),
					collaboratable_id: tasks.map(i => i.type_id),
					collaboratable_type: tasks.map(i => i.task_type + 's'),
					role: tasks.map(i => i.role || "editor")
				};
				await axiosInstance.post("/collaborators", collaboratorData);
				dispatch(getChapters({ id }));
			}, 1000);
		} catch (error) {
			console.error('Error submitting card:', error);
		}
	}, [dispatch, event_id, form, handleCancel, id, tasks, selectedFile, users]);

	console.log(tasks)
	const handleCollaboratorRole = useCallback((email) => {
		setCurrentUser({ email, visible: true });
	}, []);

	const closeCollaboratorRole = useCallback(() => {
		setCurrentUser({ email: '', role: '', visible: false });
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			await Promise.all([
				dispatch(getUsers()),
				dispatch(getTimelineList()),
				dispatch(getPlotlineList()),
				getBrainstorms(null, 1, ()=> {}, ()=> {}, dispatch)
			]);
		};
		fetchData();
	}, [dispatch]);

	console.log(bstorm, 'bs')

	const renderAdditionalOptions = useCallback((taskType, index) => {
		const options = {
            book: {
                label: "Book",
                name: `tasks[${index}].book_genre`,
                placeholder: "Select Book Genre",
                options: book_data[0]?.map(book => ({ label: book.book_name, value: book.id })) || [],
            },
            brainstorm: {
                label: "Brainstorm",
                name: `tasks[${index}].brainstorm_topic`,
                placeholder: "Select Brainstorm Topic",
                options: bstorm?.map(b => ({ label: b.brainstorm_name, value: b.id })) || [],
            },
            timeline: {
                label: "Timeline",
                name: `tasks[${index}].timeline_period`,
                placeholder: "Select Timeline Period",
                options: timelineData?.map(t => ({ label: t.name, value: t.id })) || [],
            },
            plot_planner: {
                label: "Plotline",
                name: `tasks[${index}].plotline_summary`,
                placeholder: "Select Plotline Summary",
                options: plotlines?.map(p => ({ label: p.plot_planner_title, value: p.id })) || [],
            },
        };

		const option = options[taskType];
		if (!option) return null;

		return (
			<Form.Item
				label={option.label}
				name={option.name}
				labelCol={{ span: 24 }}
				rules={[{ required: true, message: `${option.label} is required` }]}
			>
				{option.component || (
					<Select options={option.options} placeholder={option.placeholder}
					onChange={(value) => {
						console.log(value, index)
						console.log(tasks)
						const newTasks = [...tasks];
						newTasks[index].type_id = value;
						setTasks(newTasks);
					}} />
				)}
			</Form.Item>
		);
	}, [book_data, bstorm, tasks]);

	const addTask = useCallback(() => {
		setTasks([...tasks, { title: "", due_date: null, task_type: null, collabration_id: null, role: "editor" }]);
	}, [tasks]);

	const removeTask = useCallback((index) => {
		const newTasks = tasks.filter((_, i) => i !== index);
		setTasks(newTasks);
		form.setFieldsValue({
            [`tasks[${index}].title`]: undefined,
            [`tasks[${index}].due_date`]: undefined,    
            [`tasks[${index}].collaborator`]: undefined,
            [`tasks[${index}].status`]: undefined,
            [`tasks[${index}].task_type`]: undefined,
            [`tasks[${index}].timeline_period`]: undefined,
            [`tasks[${index}].role`]: undefined,
        });
	}, [tasks, form]);

	const filterUser = useCallback((id) => {
		const user = users[0].data.find(user => user.id === id);
		return user?.id;
	}, [users]);

	const renderTaskForm = useCallback((task, index) => {
		const commonFields = (
			<>
				<Form.Item
					label="Task Title"
					name={`tasks[${index}].title`}
					labelCol={{ span: 24 }}
					rules={[{ required: true, message: "Title is required" }]}
				>
					<Input onChange={(e) => {
						const newTasks = [...tasks];
						newTasks[index].title = e.target.value;
						setTasks(newTasks);
					}} />
				</Form.Item>
				<Form.Item
					label="Due Date"
					name={`tasks[${index}].due_date`}
					labelCol={{ span: 24 }}
					rules={[{ required: true, message: "Due date is required" }]}
				>
					<DatePicker
						showTime
						format="YYYY-MM-DD HH:mm:ss"
						style={{ width: "100%" }}
						placeholder="Select Due Date and Time"
						onChange={(value) => {
							const newTasks = [...tasks];
							newTasks[index].due_date = value;
							setTasks(newTasks);
						}}
					/>
				</Form.Item>
				<Form.Item
					label="Collaborator"
					name={`tasks[${index}].collaborator`}
					labelCol={{ span: 24 }}
					rules={[{ required: true, message: "Collaborator is required" }]}
				>
					<Select
						options={users[0].data.map(user => ({ label: user.name ?? user.email, value: user.id })) || []}
						placeholder="Select Collaborator"
						onChange={(value) => {
							const newTasks = [...tasks];
							newTasks[index].collabration_id = filterUser(value);
							setTasks(newTasks);
							handleCollaboratorRole(users[0].data.find(user => user.id === value)?.email);
						}}
					/>
				</Form.Item>
				<Form.Item
					label="Role"
					name={`tasks[${index}].role`}
					labelCol={{ span: 24 }}
					rules={[{ required: true, message: "Role is required" }]}
				>
					<Select
						options={[
							{ label: "Viewer", value: "viewer" },
							{ label: "Editor", value: "editor" },
						]}
						placeholder="Select Role"
						onChange={(value) => {
							const newTasks = [...tasks];
							newTasks[index].role = value;
							setTasks(newTasks);
						}}
					/>
				</Form.Item>
				<Form.Item
					label="Task Type"
					name={`tasks[${index}].task_type`}
					labelCol={{ span: 24 }}
					rules={[{ required: true, message: "Task type is required" }]}
				>
					<Select
						options={[
							{ label: "Book", value: "book" },
							{ label: "Brainstorm", value: "brainstorm" },
							{ label: "Timeline", value: "timeline" },
							{ label: "Plotline", value: "plot_planner" },
						]}
						placeholder="Select Task Type"
						onChange={(value) => {
							const newTasks = [...tasks];
							newTasks[index].task_type = value;
							setTasks(newTasks);
						}}
					/>
				</Form.Item>
				{renderAdditionalOptions(task.task_type, index)}
				<Button type="danger" onClick={() => removeTask(index)}>
					Remove Task
				</Button>
				<hr />
			</>
		);

		return (
			<div key={index}>
				{commonFields}
			</div>
		);
	}, [book_data, bstorm, filterUser, plotlines, removeTask, renderAdditionalOptions, tasks, timelineData, users]);

	return (
		<>
			<Button
				className="mt-3"
				style={{ width: "100%", textAlign: "center" }}
				onClick={hasEditor ? showDrawer : showEditorModal}
				id={id}
			>
				New Event Item
			</Button>
			<Drawer
				className="chapterModal"
				title="Add New Card"
				open={isDrawerOpen}
				onOk={handleOk}
				onClose={handleCancel}
				mask={false}
				width={400}
				placement="right"
				bodyStyle={{ padding: "1.5rem" }}
			>
				<Form
					form={form}
					layout="vertical"
					initialValues={{ remember: true }}
					onFinish={SubmitCard}
					autoComplete="off"
					className="gx-signin-form gx-form-row0"
					style={{ maxWidth: 1000 }}
				>
					<Form.Item label="Card Image">
						{showGallery && (
							<UserGallery
								setSelectedFile={setSelectedFile}
								setShowGallery={setShowGallery}
							/>
						)}
						<div className="artwork mt-3">
							<button
								className="btn-artwork"
								type="button"
								onClick={() => setShowGallery(!showGallery)}
							>
								<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
							</button>
							{selectedFile && (
								<div className="artworkContainer mt-2">
									<button
										onClick={(e) => {
											e.preventDefault();
											setSelectedFile(null);
										}}
										type="button"
										className="btn-remove"
									>
										Remove
									</button>
								</div>
							)}
						</div>
						{customErrors.image_url && (
							<div className="error text-danger">{customErrors.image_url}</div>
						)}
					</Form.Item>
					<Form.Item
						label="Card Title"
						name="card_title"
						labelCol={{ span: 24 }}
						rules={[{ required: true, message: "Title is required" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Card Description"
						name="description"
						labelCol={{ span: 24 }}
						rules={[{ required: true, message: "Description is required" }]}
					>
						<Input.TextArea />
					</Form.Item>
					{tasks.map((task, index) => renderTaskForm(task, index))}
					<Button type="dashed" className="mb-2" onClick={addTask} block>
						{tasks.length === 0 ? "Add Task" : "Add Another Task"}
					</Button>
					<div className="d-flex flex-wrap gap-2 pb-4">
						{selectedUsers.map((userId) => {
							const user = users[0].data.find((u) => u.id === userId);
							return (
								<Avatar
									key={userId}
									size="default"
									src={user?.avatar}
									icon={!user?.avatar && <UserOutlined />}
								/>
							);
						})}
					</div>
					<Form.Item
						label="Color"
						name="color_id"
						labelCol={{ span: 24 }}
						rules={[{ required: true, message: "Color is required" }]}
					>
						<Select options={colors} placeholder="Select Color" />
					</Form.Item>
					<Form.Item
						label="Attachments"
						name="attachments"
						labelCol={{ span: 24 }}
						rules={[{ required: false }]}
					>
						<Upload>
							<Button icon={<UploadOutlined />}>Upload Attachments</Button>
						</Upload>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" block>
							Add Card
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</>
	);
};

export default EventItemCard;