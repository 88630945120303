import React, { useEffect, useState, useRef } from "react";
import {
	Drawer,
	Button,
	message,
	Form,
	Modal,
	Select as AntSelect,
	Input,
	Divider,
	Switch,
} from "antd";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";
import newBook from "../../assets/images/newbook.png";
import { getColorData } from "../../redux/Slice/ColorSlice";
import UserGallery from "../gallery/UserGallery";
import axios from "axios";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import { CollaborativeShareField } from "../collaborativeShareField";

const AddBrainstormDrawer = ({ open, close, books, getBrainstormsByBook }) => {
	const selectColor = {
		value: "",
		label: "-- Select Color --",
	};
	const selectBook = {
		value: "",
		label: "-- Select Book --",
	};
	const [brainstormName, setBrainstormName] = useState("");
	const [brainstormDescription, setBrainstormDescription] = useState("");
	const [selectedColor, setSelectedColor] = useState(selectColor);
	const [selectedBook, setSelectedBook] = useState(selectBook);
	const [selectedFile, setSelectedFile] = useState(null);
	const [showGallery, setShowGallery] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({ email: "", visible: false });

	const [colorOptions, setColorOptions] = useState([]);
	const [error, setError] = useState({});

	const dispatch = useDispatch();
	const { colors } = useSelector((state) => state.colors);
	const { book_data } = useSelector((state) => state.books);
	const { users } = useSelector((state) => state.user);
	const form = useRef();
	const [forms] = Form.useForm();
	console.log(users);

	useEffect(() => {
		dispatch(getColorData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const options = colors.map((color) => {
			return {
				value: color.id,
				label: color.color,
			};
		});

		setColorOptions(() => [selectColor, ...options]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colors]);

	const resetForm = () => {
		setSelectedBook(selectBook);
		setSelectedColor(selectColor);
		setSelectedFile(null);
		setBrainstormName("");
		setBrainstormDescription("");
		setError({});
		setSelectedUsers([]);
		forms.resetFields();
	};

	const saveBrainstorm = async (e) => {
		const token = localStorage.getItem("token");
		const brainstormData = {
			book_id: selectedBook.value,
			brainstorm_name: brainstormName,
			description: brainstormDescription,
			color_id: selectedColor.value,
			image_id: selectedFile?.id ?? null,
		};

		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}/brainstorms`, brainstormData, {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "application/json",
				},
			})
			.then((response) => {
				const brainstormId = response.data.data.id;
				const collaboratorData = {
					email: selectedUsers.map((user) => user.email),
					role: selectedUsers.map((user) => user.role),
					collaboratable_type: "brainstorms",
					collaboratable_id: brainstormId,
				};
				axios
					.post(
						`${process.env.REACT_APP_API_URL}/collaborators`,
						collaboratorData,
						{
							headers: {
								Authorization: `Bearer ${token}`,
								Accept: "application/json",
							},
						}
					)
					.then(() => {
						getBrainstormsByBook(selectedBook);
						resetForm();
						setLoading(false);
						close();
					})
					.catch((error) => {
						message.error("Failed to add collaborators");
						setLoading(false);
					});
			})
			.catch((error) => {
				if (error.response.status === 422) {
					setError(error.response.data.errors);
				} else if (error.response.status === 403) {
					message.error(error.response.data.message);
				}
				setLoading(false);
			});
	};

	const handleCollaboratorRole = (email) => {
		setCurrentUser({ email: email, visible: true });
	};

	const closeCollaboratorRole = () => {
		setCurrentUser({ email: "", role: "", visible: false });
	};

	const handleCancel = () => {
		resetForm();
		close();
	};

	return (
		<Drawer
			title={"Add New Brainstorm"}
			placement="right"
			onClose={handleCancel}
			open={open}
			className="p-1 drawer data-drawer"
			zIndex={9999}
			autoFocus={false}
			mask={false}
		>
			<Form
				ref={form}
				form={forms}
				labelCol={{
					span: 24,
				}}
				wrapperCol={{
					span: 24,
				}}
				layout="vertical"
				initialValues={{
					remember: false,
				}}
				onFinish={saveBrainstorm}
				autoComplete="off"
				className="gx-signin-form gx-form-row0"
				style={{
					position: "relative",
				}}
			>
				<Form.Item label="Artwork">
					{showGallery && (
						<UserGallery
							setSelectedFile={setSelectedFile}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={(e) => {
										e.preventDefault();
										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
					{error.image_url && (
						<div className="error text-danger">{error.image_url}</div>
					)}
				</Form.Item>
				<Form.Item
					label="Brainstorm Name"
					name="brainstorm_name"
					rules={[
						{
							required: true,
							message: "Brainstorm Name is required",
						},
					]}
				>
					<Input
						placeholder="Brainstorm Name"
						value={brainstormName}
						onChange={(e) => setBrainstormName(e.target.value)}
					/>
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{
							required: true,
							message: "Description is required",
						},
					]}
				>
					<TextAreaInput
						name="description"
						placeholder="Add Description"
						value={brainstormDescription}
						onChange={(e) => setBrainstormDescription(e.target.value)}
					/>
				</Form.Item>
				<Form.Item
					label="Select Book"
					name="book_id"
					rules={[
						{
							required: true,
							message: "Please select a book",
						},
					]}
				>
					<Select
						options={[selectBook, ...books]}
						onChange={(e) => {
							setSelectedBook(e);
						}}
						isSearchable={true}
						className="select-book"
						value={selectedBook}
					/>
					{error.book_id && <span className="error">{error.book_id}</span>}
				</Form.Item>
				<Form.Item
					label="Select Color"
					name="color_id"
					rules={[
						{
							required: true,
							message: "Color is required",
						},
					]}
				>
					<Select
						options={colorOptions}
						onChange={(e) => {
							setSelectedColor(e);
						}}
						isSearchable={true}
						className="select-book"
						value={selectedColor}
					/>
					{error.color_id && <span className="error">{error.color_id}</span>}
				</Form.Item>
				<Divider />
				<CollaborativeShareField
					label="Share Brainstorm"
					isRequired={false}
					handleCollaboratorRole={handleCollaboratorRole}
					closeCollaboratorRole={closeCollaboratorRole}
					Form={forms}
					users={users}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
				/>

				<div className="buttons-container d-flex mt-2">
					<Button
						type="primary"
						htmlType="submit"
						className="mr-2"
						loading={loading}
						disabled={loading}
					>
						Save
					</Button>
					<Button onClick={handleCancel} type="ghost">
						Cancel
					</Button>
				</div>
			</Form>
			<Modal
				centered
				title="Select Collaborator Role"
				open={currentUser.visible && currentUser.email !== ""}
				onOk={closeCollaboratorRole}
				onCancel={closeCollaboratorRole}
			>
				<div className="custom-form">
					<label className="custom-label">Role</label>
					<AntSelect
						placeholder="Select a role"
						className="custom-select"
						defaultValue="viewer"
						onChange={(value) => {
							setSelectedUsers(
								selectedUsers.map((item) =>
									item.email === currentUser.email
										? { ...item, role: value }
										: item
								)
							);
						}}
					>
						<AntSelect.Option value="viewer">Viewer</AntSelect.Option>
						<AntSelect.Option value="editor">Editor</AntSelect.Option>
					</AntSelect>
					{!selectedUsers.find((item) => item.email === currentUser.email)
						?.role && <span className="error">Please select a role</span>}
				</div>
			</Modal>
		</Drawer>
	);
};

export default AddBrainstormDrawer;
