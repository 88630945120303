import { useEffect, useContext, useState } from "react";
import { Row, Col, Button, Dropdown } from "antd";
import "./index.css";
import { getCurrentUser, LogoutFunc } from "../../redux/Slice/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { HeaderDataContext } from "../../context/HeaderContext";
import { useResetAllState } from "../../helpers/resetAllState";
import FullPageSpinner from "../loader/FullPageSpinner";
import { UserOutlined } from "@ant-design/icons";
import { resetStore } from "../..";
import { AiOutlineMenu } from "react-icons/ai";

function Header() {
    // useEffect(() => window.scrollTo(0, 0));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const router = useNavigate();
    const { resetAll } = useResetAllState();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state?.auth);
    const user_name = user[0]?.user?.name;
    const profile_image = user[0]?.user?.profile_image;
    const header = useContext(HeaderDataContext);
    const hasPackage = localStorage.getItem("hasPackage") === "true";
    const logout = async () => {
        try {
            await dispatch(LogoutFunc());
            await resetAll();
            localStorage.removeItem("token");
            localStorage.removeItem("authenticated");
        } catch (err) {
            console.error(err);
        } finally {
            window.location.reload()
        }
    };
    useEffect(() => {
        dispatch(getCurrentUser(logout));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const items = [
        {
            key: "1",
            label: <span>{user_name}</span>,
        },
        {
            key: "2",
            label: (
                <span>
                    <Link to="/profile-setting">Settings</Link>
                </span>
            ),
        },
        {
            key: "3",
            label: (
                <span>
                    {" "}
                    <button type="button" onClick={logout} className="logout">
                        Logout
                    </button>
                </span>
            ),
        },
    ];
    const menuProps = {
        items,
        selectable: true,
    };
    console.log(profile_image, 'image')
    return (
        <>
        <Row justify="space-between" className="align-center">
            <Col
                xs={18}
                sm={18}
                md={18}
                lg={18}
                xl={19}
                xxl={20}
                className="border-r header-rt"
            >
                <p className="location_name">{header.heading}</p>
            </Col>
            <Col className="display-flex header-lt" xs={5} sm={5} md={5} lg={5} xl={4} xxl={3}>
                {profile_image ? (
                    <img
                        src={profile_image}
                        className="mr-2 icon-35 mob-hide"
                        alt="Avatar"
                        id="profile_image"
                    />
                ) : (
                    <UserOutlined className="mr-2 icon-35" id="profile_icon" />
                )}
                <Dropdown menu={menuProps} trigger={["click"]} className="mob-hide">
                    <Button>My Profile</Button>
                </Dropdown>
                <div className="mobile-dropdown mob-show">
                    <Dropdown menu={menuProps} trigger={["click"]}>
                    {profile_image ? (
                        <img
                            src={profile_image}
                            className="mr-2 icon-35"
                            alt="Avatar"
                            id="profile_image"
                        />
                    ) : (
                        <UserOutlined className="mr-2 icon-35" id="profile_icon" />
                    )}
                    </Dropdown>
                </div>
            </Col>
        </Row>
        </>
    );
}
export default Header;