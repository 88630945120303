import { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookData, postBookData } from "../../redux/Slice/BookSlice";
import { getSeriesData, postSeriesData } from "../../redux/Slice/SeriesSlice";
import { HeaderDataContext } from "../../context/HeaderContext";
import CreateBookIcon from "../../assets/icons/create-book.svg";
import { ACTIVITY_LOGS } from "../../constant/constant";
import Books from "../../components/cards/Books";
import CustomButton from "../../components/button/Button";
import { ActivityLog } from "../../components/cards/ActivityLog";
import SeriesCards from "../../components/cards/Series";
import { SubscriptionModal } from "../../components/subscriptionodal";
import { Spin, message } from "antd";
import { mapFeaturesToButtons } from "../../components/PackageSubscriptionHook";
import { DashboardActivityLog } from "../../components/cards/DashboardActivityLog";
import { apiServices } from "../../services/apiServices/Api";
import { useNavigate } from "react-router-dom";
const useBooksAndSeries = () => {
	const navigate = useNavigate();
	const header = useContext(HeaderDataContext);
	header.setHeading("Books");
	const ref = useRef(null);
	const { user } = useSelector((state) => state.auth);
	const [messageApi, contextHolder] = message.useMessage();
	const [subscriptionModal, setSubscriptionModal] = useState(false);
	const [controlledButtons, setControlledButtons] = useState({
		createSeries: false,
		createBook: false,
		feature: "",
	});
	const [clickedButton, setClickedButton] = useState([]);
	const [createBook, setCreateNewBook] = useState(false);
	const [cardStatus, setCardStatus] = useState(1);
	const { loading, book_data } = useSelector((state) => state.books);
	const [activityLogs, setActivityLogs] = useState([]);
	useEffect(() => {
		fetchActivityLogs(user[0]?.user?.id);
	}, []);
	const fetchActivityLogs = async (userId) => {
		try {
			const response = await apiServices.get(`/logs/list/${userId}`, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			if (response.data) {
				setActivityLogs(response.data);
			} else {
				console.error("Empty response data or invalid structure:", response);
			}
		} catch (err) {
			console.error("Error fetching recent updates:", err);
		} finally {
		}
	};
	const user_name = user[0]?.user?.name;
	const checkSubscription = (action) => {
		const featureMap = {
			"series-feature": "createSeries",
			"full-planning-features-for-a-new-book": "createBook",
		};
		const updatedButtons = mapFeaturesToButtons(featureMap, action);
		setControlledButtons(updatedButtons);
	};
	const openSubscriptionModal = () => {
		setSubscriptionModal(true);
	};
	const createNewBookDrawer = (status) => {
		setCardStatus(status);
		setCreateNewBook(true);
		setSelect(status === 1 ? "All Series" : "All Books");
	};
	const closeCreateBookDrawer = () => {
		setCreateNewBook(false);
	};
	const dispatch = useDispatch();
	const booksData = () => {
		dispatch(getBookData());
	};
	const seriesData = () => {
		dispatch(getSeriesData());
	};
	useEffect(() => {
		booksData();
		seriesData();
	}, [dispatch]);
	const [select, setSelect] = useState("All Books");
	const options = [
		{ value: "All Series", text: "All Series" },
		{ value: "All Books", text: "All Books" },
	];
	const booksContentSection = () => (
		<>
			<h2 className="sub-title">Start something new:</h2>
			<div className="btn-spacing">
				<CustomButton
					type="primary"
					buttonTitle={"Create New Series"}
					onClick={() =>
						controlledButtons.createSeries
							? createNewBookDrawer(1)
							: (setClickedButton([{ feature: "series-feature" }]),
							  openSubscriptionModal())
					}
					icon={<img src={CreateBookIcon} alt="Button Icon" />}
				/>
				<CustomButton
					buttonTitle={"Create New Book"}
					onClick={() =>
						controlledButtons.createBook
							? createNewBookDrawer(2)
							: (setClickedButton([
									{ feature: "full-planning-features-for-a-new-book" },
							  ]),
							  openSubscriptionModal())
					}
				/>
			</div>
			<div className="mb-5">
				<div className="display-flex space-between">
					<h2 className="sub-title">Pick up where you left off:</h2>
					<p className="text-16 font-w-500 light-grey">Recent Updates</p>
				</div>
			</div>
			<div>
				<div className="display-flex space-between">
					{select === "All Books" ? (
						<h2 className="sub-title"> Your Books </h2>
					) : (
						<h2 className="sub-title">Your Series</h2>
					)}
					<h2 className="sub-title-16 font-w-500 light-grey">View All</h2>
				</div>
				{select === "All Books" ? (
					<Books
						disable={!controlledButtons.createBook}
						openSubscriptionModal={openSubscriptionModal}
					/>
				) : (
					<SeriesCards
						disable={!controlledButtons.createSeries}
						openSubscriptionModal={openSubscriptionModal}
					/>
				)}
				<SubscriptionModal
					open={subscriptionModal}
					ok={openSubscriptionModal}
					features={clickedButton}
					cancel={() => setSubscriptionModal(false)}
				/>
			</div>
		</>
	);
	const activityLogSection = () => (
		<>
			<div className="display-flex space-between ml-5 mr-5">
				<h2 className="sub-title">Activity log</h2>
				<h2
					className="text-16 font-w-500 light-grey cursor"
					onClick={() => navigate("/activity-details")}
				>
					View All
				</h2>
			</div>
			{loading ? (
				<Spin
					tip="Loading"
					size="large"
					className="display-flex center"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "100px",
					}}
				></Spin>
			) : (
				activityLogs.map((item, index) => (
					<DashboardActivityLog logsData={activityLogs[index]} />
				))
			)}
		</>
	);
	const success = () => {
		messageApi.open({
			type: "success",
			content:
				cardStatus === 1
					? "Series added successfully"
					: "Book added successfully",
		});
	};
	const error = () => {
		messageApi.open({
			type: "error",
			content: "Something went wrong",
		});
	};

	const onAddData = async (data) => {
		return new Promise((resolve, reject) => {
			try {
				if (cardStatus === 1) {
					dispatch(postSeriesData({ data }));
					setTimeout(() => {
						dispatch(getSeriesData());
					}, 2000);
				} else {
					dispatch(postBookData({ data }));
					setTimeout(() => {
						dispatch(getBookData());
					}, 900);
				}
				closeCreateBookDrawer();
				success();
				resolve(true);
			} catch (e) {
				error();
				reject(e);
			}
		});
	};
	return {
		contextHolder,
		ref,
		checkSubscription,
		select,
		setSelect,
		options,
		booksContentSection,
		activityLogSection,
		createBook,
		cardStatus,
		onAddData,
		closeCreateBookDrawer,
	};
};
export { useBooksAndSeries };
