import React from "react";
import { Row, Col, Card, Modal } from "antd";
import BSRounds from "./BSRounds";
import { useParams } from "react-router-dom";
import FullPageSpinner from "../loader/FullPageSpinner";
import "./recorder.css";
import BookDescription from "./BookDescription";
import RoundPlayer from "./RoundPlayer";
import Recorder from "./Recorder";
import { AUTHENTICATED_ROUTES } from "../../constant/routeConstant";
import { useBrainstormDetails } from "../../hooks/brainstrom/useBrainstromDetials";

const BrainstormDetails = ({ mod }) => {
	const { id } = useParams();
	const {
		hasEditor,
		loading,
		isError,
		errorMessage,
		contextHolder,
		brainstorm,
		addNew,
		recorder,
		toggleRecording,
		error,
		recordBlob,
		bsTranscript,
		setBSTranscript,
		onAddNew,
		saveRound,
		refRoundTitle,
		roundTranscript,
		disableInput,
		roundLoading,
		deleteRound,
		roundIndex,
		selectRound,
		isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
	} = useBrainstormDetails({ id, mod });

	return (
		<div className="main-container">
			<BookDescription
				link={AUTHENTICATED_ROUTES.BRAINSTORM}
				name={loading ? "Loading Name..." : brainstorm.brainstorm_name}
				description={
					loading ? "Loading Description..." : brainstorm.description
				}
				mod={mod}
			/>
			{loading ? (
				<FullPageSpinner />
			) : (
				<>
					{contextHolder}
					<Card
						style={{
							borderRadius: "8px",
							boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
							padding: "12px",
						}}
						className="mt-5"
					>
						<Row>
							<Col sm={24} xs={24} md={24} lg={24} xxl={24}>
								{addNew ? (
									<>
										<Recorder
											recorder={recorder}
											toggleRecording={toggleRecording}
											error={error}
											mod={mod}
											hasEditor={hasEditor}
										/>
										{/* <div id="recordedAudio"></div> */}
									</>
								) : (
									brainstorm.rounds.length > 0 && (
										<RoundPlayer
											rounds={brainstorm.rounds}
											roundIndex={roundIndex}
											selectRound={selectRound}
											deleteRound={deleteRound}
											hasEditor={hasEditor}
											mod={mod}
										/>
									)
								)}
							</Col>
						</Row>
						<BSRounds
							hasEditor={hasEditor}
							mod={mod}
							showEditorModal={showEditorModal}
							recordBlob={recordBlob}
							rounds={brainstorm.rounds}
							roundIndex={roundIndex}
							selectRound={selectRound}
							bsTranscript={bsTranscript}
							setBSTranscript={setBSTranscript}
							onAddNew={onAddNew}
							addNew={addNew}
							saveRound={saveRound}
							refRoundTitle={refRoundTitle}
							roundTranscript={roundTranscript}
							error={error}
							disableInput={disableInput}
							roundLoading={roundLoading}
							deleteRound={deleteRound}
						/>
					</Card>
				</>
			)}
			<Modal
				title="Access Denied"
				visible={isEditorModalVisible}
				onOk={handleEditorModalOk}
				onCancel={handleEditorModalCancel}
				centered
				footer={null}
			>
				<p>You do not have permission to perform this action.</p>
			</Modal>
		</div>
	);
};

export default BrainstormDetails;
